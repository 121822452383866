const formatDateMonth = (v) => {
    return v > 9 ? v : `0${v}`;
  };

export const formatDate = (d) => {
    if (!d) d = new Date();
    if (typeof(d) !== Date) d = new Date(d);
    return `${d.getFullYear()}-${formatDateMonth(
        d.getMonth() + 1
    )}-${formatDateMonth(d.getDate())}`;
};

export const formatTime = (d) => {
    if (!d) d = new Date();
    return `${d.getHours() <= 9 ? '0' : ''}${d.getHours()}:${d.getMinutes() <= 9 ? '0' : ''}${d.getMinutes()}`
}

export const dateString = (d, hyphenated=false) => {
    let date = new Date(d);
    let dateString = date.toLocaleDateString('en-GB', { day: '2-digit', year: 'numeric', month: 'short' })
    if (hyphenated) {
        return dateString.replace(/ /g, '-');
    }
    return dateString
}