import React from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    IconButton,
} from "@mui/material";
import TrashIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/EditNote";
import { dateString } from "../../utils/date";

const ExpensesTable = ({
    data = [],
    currency,
    role,
    setEdit = () => {},
    setDelete = () => {},
}) => {
    const headers = [
        {
            label: "Date",
            field: "date",
            align: "left",
        },
        {
            label: "Type",
            field: "type",
            align: "left",
        },
        {
            label: "Item",
            field: "item",
            align: "left",
        },
        {
            label: "Amount",
            field: "amount",
            align: "right",
        },
        {
            label: "Payment Method",
            field: "payment_method",
            align: "right",
        },
    ];

    if (role !== "manager") {
        headers.push({
            label: "Actions",
            field: "actions",
            align: "right",
        });
    }

    return (
        <TableContainer sx={{ width: "100%" }} component={Paper}>
            <Table sx={{ width: "100%" }}>
                <TableHead>
                    <TableRow key={"head"}>
                        {headers.map((h) => (
                            <TableCell
                                sx={{
                                    padding: "10px 16px",
                                    color: (theme) =>
                                        theme.palette.text.secondary,
                                }}
                                align={h.align}
                            >
                                {h.label}
                                {h.field === "amount" && ` (${currency})`}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((r) => (
                        <TableRow>
                            {headers.map((h) => (
                                <TableCell align={h.align}>
                                    {h.field === "actions" ? (
                                        <>
                                            <IconButton
                                                sx={{ p: 0 }}
                                                onClick={() => setEdit(r)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                sx={{ p: 0 }}
                                                onClick={() => setDelete(r.id)}
                                            >
                                                <TrashIcon />
                                            </IconButton>
                                        </>
                                    ) : h.field === "date" ?
                                        dateString(r[h.field], true) :
                                    (
                                        r[h.field]
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ExpensesTable;
